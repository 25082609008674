import React, { useEffect, useState } from 'react';
import SEO from '../components/seo';
import axios from 'axios';
import { useLocale } from '@util/providers/Locale';
import PartnersLoadingModal from '@util/Modals/partnersLoadingModal';
import { useTranslation } from 'react-i18next';
import PageHeader from '@ui/PageHeader';
import SubscriptionLicenseNavigator from '@components/Miscellaneous/SubscriptionLicenseNavigator';
import useSubscription from '@util/hooks/useSubscription';
import { navigate } from 'gatsby';

export default function Partner() {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const [partners, setPartners] = useState();
  const { isSubscriber, isActiveSubscriber } = useSubscription();
  isSubscriber && isActiveSubscriber && navigate('/');

  const [loadingPartners, setLoadingPartners] = useState();
  useEffect(() => {
    let url =
      process.env.INTERNAL_SERVICE_URL +
      'admin/partnerregions/' +
      locale?.localeDetails?.isoCode;
    setLoadingPartners(true);
    axios(url, {
      method: 'GET',
    })
      .then(function(response) {
        if (response.status >= 200 && response.status < 300) {
          return response;
        }
        throw Error(response.statusText);
      })
      .then(function(response) {
        setPartners(response.data);
      })
      .catch(err => {
        console.error(err.message);
      })
      .finally(() => {
        setLoadingPartners(false);
      });
    // eslint-disable-next-line
  }, [, locale]);

  return (
    <>
      <SEO title="Locate a partner" />
      <PageHeader className="pb-3">{t('page:partner')}</PageHeader>
      <p className="pb-2">{t('partner.body')}</p>
      <hr />
      <div className="py-3">
        <h3>{locale?.localeDetails?.name}</h3>
      </div>
      <div className="row">
        {partners &&
          partners.map(partner => {
            return (
              partner.enabled && (
                <div
                  className=" col-lg-4 col-md-6 col-12 mb-5"
                  key={partner.id}
                >
                  <p className="font-weight-bold">{partner.name}</p>

                  <p className="mt-2 mb-0">{partner.address1}</p>
                  <p className="m-0">{partner.address2}</p>
                  <p className="m-0">{partner.country}</p>
                  <p className="m-0">
                    {t('forms.phone')}: {partner.phone}
                  </p>
                  <p className="m-0">
                    {t('forms.email')}:{' '}
                    <a href={'mailto: ' + partner.email}>{partner.email}</a>
                  </p>
                  <button
                    className="btn btn-primary mt-3 ml-0"
                    onClick={() => {
                      window.open(partner.url, '_blank');
                    }}
                  >
                    {t('visitPartnerSite')}
                  </button>
                </div>
              )
            );
          })}
      </div>
      <hr />
      <p>
        {t('partner.contactUs')}
        <a href={process.env.CONTACT_US} target="_blank">
          {t('partner.contactCustomerService')}
        </a>
      </p>
      {!locale?.localeDetails?.exclusive && <SubscriptionLicenseNavigator />}
      <PartnersLoadingModal isOpen={loadingPartners} />
    </>
  );
}
