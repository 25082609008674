import React from 'react';
import { Link } from 'gatsby';
import { useTranslation, Trans } from 'react-i18next';

export default function SubscriptionLicenseNavigator() {
  const { t } = useTranslation();

  return (
    <div className="text-center mt-4">
      Prefer a&nbsp;
      <Link to="/">{t('subscriptionLicense.toggle.subscription')}</Link>
      &nbsp;or&nbsp;
      <Link to="/license">{t('subscriptionLicense.toggle.tolicenseText')}</Link>
      ?
    </div>
  );
}
